export interface ICurrentProjectState {
    isCompleteProjectModalOpen: boolean;
    isExportAnnotationModalOpen: boolean;
    selectedReader: string;
    selectedReviewReader: string;
}

export function CurrentAnnotationStateFactory(data?: Partial<ICurrentProjectState>): ICurrentProjectState {
    return {
        isCompleteProjectModalOpen: data?.isCompleteProjectModalOpen ?? false,
        isExportAnnotationModalOpen: data?.isExportAnnotationModalOpen ?? false,
        selectedReader: data?.selectedReader ?? null,
        selectedReviewReader: data?.selectedReviewReader ?? null,
    };
}
