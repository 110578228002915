import { Dropdown, DropDownProps } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import cx from 'classnames';
import React from 'react';
import Styles from './Styles.module.scss';

export default function GDropdown({ onOpenChange, className, overlayClassName, menu, menuItemList, ...props }: IDropDownProps) {
    return (
        <div
            className={cx(Styles.DropdownContainer, className)}
            id={props.id}
        >
            <Dropdown
                onOpenChange={onOpenChange}
                overlayClassName={cx(Styles.DropdownMenu, overlayClassName)}
                menu={
                    menu || {
                        items: menuItemList
                            ?.filter(i => !i.hidden)
                            .map(item => ({
                                key: item.key,
                                label: item.render,
                                disabled: item.disabled,
                                icon: item.icon,
                                ...item,
                            })),
                    }
                }
                {...props}
            >
                {props.children}
            </Dropdown>
        </div>
    );
}

export const GDropdownClassName = Styles.DropdownContainer;
export const GDropdownMenuClassName = Styles.DropdownMenu;

interface IDropDownProps extends Partial<DropDownProps> {
    children: React.ReactNode;
    dropdownRender?: (menu: React.ReactNode) => React.ReactNode;
    menuItemList?: Array<IDropdownMenuItem>;
    overlayClassName?: string;
    placement?: TPlacement;
    id?: string;
}

export interface IDropdownMenuItem extends MenuItemType {
    key: string;
    render: React.ReactNode;
    disabled?: boolean;
    icon?: React.ReactNode;
    hidden?: boolean;
}

export type TPlacement = 'bottomLeft' | 'bottom' | 'top' | 'bottomRight' | 'topLeft' | 'topCenter' | 'topRight' | 'bottomCenter';
