export const enMessages = {
    '000001': 'Model',
    '000002': 'Validation',
    '000003': 'Data',
    '000004': 'Platform',
    '000005': 'Summary',
    '000006': 'Completed',
    '000007': 'Model Stats',
    '000008': 'Models',
    '000009': 'Dataset Stats',
    '000010': 'Validation Runs',
    '000011': 'Last Month',
    '000012': 'Last Week',
    '000013': 'Linked Validations',
    '000014': 'Anatomy Parts',
    '000015': 'Model Sources',
    '000016': 'Continue with Google',
    '000017': ' in progress',
    '000018': 'Model Name',
    '000019': 'Model Type',
    '000020': 'Completion',
    '000021': 'Modality',
    '000022': 'Dataset',
    '000023': 'Anatomy',
    '000024': 'Date',
    '000025': 'Model Tag',
    '000026': 'Metrics',
    '000027': 'Details',
    '000028': 'Deployed Models',
    '000029': 'Inference',
    '000030': 'Model ID',
    '000031': 'To archive model, first stop it.',
    '000032': 'Class Mapping',
    '000033': 'Framework',
    '000034': 'Model Version',
    '000035': 'Macro Precision',
    '000036': 'Access Management',
    '000037': 'Compatible Datasets',
    '000038': 'Tags',
    '000039': 'Dataset Name',
    '000040': 'Source (Hospital)',
    '000041': 'Annotation',
    '000042': 'Created / Updated',
    '000043': 'Dataset Size',
    '000044': 'Dataset Version',
    '000045': 'Source Device',
    '000046': 'Micro Precision',
    '000047': 'Meta',
    '000048': 'Gender',
    '000049': 'Model Card',
    '000050': 'Data Card',
    '000051': 'Accuracy',
    '000052': 'Micro F1',
    '000053': 'Macro AUC',
    '000054': 'Macro F1',
    '000055': 'AUC',
    '000056': 'Are you sure you want to remove this model?',
    '000057': 'Micro AUC',
    '000058': 'TP',
    '000059': 'TN',
    '000060': 'FP',
    '000061': 'FN',
    '000062': 'Sensitivity',
    '000063': 'Specificity',
    '000064': 'Precision',
    '000065': 'FPR',
    '000066': 'Basic Model Stats',
    '000067': 'Validation Stats',
    '000068': 'Training Stats',
    '000069': 'Overall Confusion Matrix',
    '000070': 'Dual Comparison',
    '000071': 'Plot Top Losses',
    '000072': 'Ground Truth',
    '000073': 'Prediction',
    '000074': 'Loss',
    '000075': 'Confidence',
    '000076': 'Show Activations',
    '000077': 'Hide Correct Predictions',
    '000078': 'Training vs Validation Performance',
    '000079': 'Performance by Confidence Threshold',
    '000080': 'Class Performance',
    '000081': 'Study Alias',
    '000082': 'Series Number',
    '000083': 'Hide Empty Instances',
    '000084': 'Study Id',
    '000085': 'Dataset removed successfully!',
    '000086': 'Are you sure you want to remove this dataset?',
    '000087': 'Models',
    '000088': 'Upload Models',
    '000089': 'Deployed',
    '000090': 'Image Id',
    '000091': 'Validation Metrics',
    '000092': 'Start Validation',
    '000093': 'Jobs Submitted',
    '000094': 'Upload',
    '000095': 'Sample Size',
    '000096': 'Model removed successfully!',
    '000097': 'Back to Models',
    '000098': 'Back to Datasets',
    '000099': 'Micro Precision',
    '000100': 'Model removed successfully!',
    '000101': 'Last Validation',
    '000102': 'Brush Configs',
    '000110': 'Datasets',
    '000111': 'Create Dataset',
    '000112': 'Smart Segmentation',
    '000113': 'All Models',
    '000120': 'Email',
    '000121': 'Sign In',
    '000122': 'Sign Up',
    '000123': 'Display Name',
    '000124': 'Password',
    '000125': 'Repeat Password',
    '000126': 'Remember me',
    '000127': 'Get Started',
    '000128': 'Welcome to ',
    '000129': 'Wget',
    '000130': 'Connect your algorithm to multiple and diverse data sources',
    '000131': 'We connect healthcare data stakeholders in a privacy-first platform to create clinical-grade AI',
    '000132': 'Please input your $',
    '000133': 'Please confirm your password!',
    '000134': 'The two passwords that you entered do not match!',
    '000135': 'Create Account',
    '000136': 'Type local path',
    '000137': 'Forgot Password',
    '000138': 'Annotation not completed!',
    '000139': 'Password Help',
    '000140': 'Enter the email address associated with your Gesund.ai account.',
    '000141': 'Continue',
    '000142': 'Back to login',
    '000143': 'Create New Password',
    '000144': 'From now on, we will ask you to enter this password every time you log in.',
    '000145': 'Secure password tips',
    '000146':
        'Use at least 13 characters. Password should include at least 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character (@$!%*?&.,).',
    '000147': 'Do not reuse previously used passwords or their variants.',
    '000148':
        'Do not use commonly used passwords, dictionary words, your name, e- mail address, or other easily found personal information.',
    '000149': 'Do not use the same password for your different online accounts.',
    '000150': 'Save Changes',
    '000151': 'Log Out',
    '000152': 'Show Pre Annotation',
    '000153': 'Deployed',
    '000154': 'Smart Rectangle Annotation',
    '000155': 'Not Deployed',
    '000160': 'Batch Size',
    '000161': 'Number of images',
    '000162': 'Start Validation Run',
    '000163': 'Running Jobs',
    '000164': 'Memory',
    '000165': 'CPU',
    '000166': 'Created',
    '000167': 'Last Deployed',
    '000168': 'Last Validation',
    '000169': 'Submit',
    '000170': 'Brightness',
    '000171': 'Contrast',
    '000172': 'Blur',
    '000173': 'Saturation',
    '000174': 'Sharpen',
    '000175': 'Random Noise',
    '000176': 'Pixelate',
    '000177': 'Opacity',
    '000178': 'Prediction Result',
    '000179': 'Predict',
    '000180': 'Smart Point Annotation',
    '000181': 'How confident you are with your assessment?',
    '000182': 'Medical Transformation',
    '000183': 'Custom Augmentation',
    '000184': 'Rate assessments',
    '000185': 'Zoom In',
    '000186': 'Zoom Out',
    '000187': 'HFlip',
    '000188': 'VFlip',
    '000189': 'Confidence Score',
    '000190': 'Raw image / Reset',
    '000191': 'Audit',
    '000192': 'Invert [I]',
    '000193': 'Emboss',
    '000194': 'Remove Color',
    '000195': 'Setup Gesund.ai Platform Settings',
    '000196': 'Select a Model',
    '000197': 'Select a Dataset',
    '000198': 'Select a Validation or Batch Run',
    '000199': 'Upload Model',
    '000200': 'We could not find the page you are looking for.',
    '000201': 'Please try again later!',
    '000202': 'Go to Playground',
    '000203': 'Go to Validation',
    '000204': 'Page',
    '000205': ' under treatment. ',
    '000206': 'We will publish after healing it over.',
    '000207': ' Please try again later!',
    '000208': 'Playground',
    '000209': 'All',
    '000210': 'Activities',
    '000211': 'System Messages',
    '000212': 'Gesund.ai News',
    '000213': 'Account',
    '000214': 'Notifications',
    '000215': 'Security',
    '000216': 'Privacy',
    '000217': 'NOTIFICATION',
    '000218': 'Longitudinal View',
    '000219': 'Hide read notifications',
    '000220': 'Go to settings',
    '000221': 'Mark all as read',
    '000222': 'Delete all notifications',
    '000223': 'Auto Save',
    '000224': 'View all notifications',
    '000225': 'No new notifications',
    '000226': 'Notification',
    '000227': 'My Activities',
    '000228': 'Settings',
    '000229': 'Subcohorts',
    '000230': 'You have successfully changed your password.',
    '000231': 'Verification email sent... please check your email and complete your sign up process.',
    '000232': 'Company Name',
    '000233': 'Website',
    '000234': 'Personal Information',
    '000235': 'Information about your account.',
    '000236': 'Profile Photo',
    '000237': 'Are you sure you want to leave? You will lose all your progress.',
    '000238': 'Cancel',
    '000239': 'Company Information',
    '000240': 'Company information of account.',
    '000241': 'Get notifications in this browser',
    '000242': 'Model deployed start and finish.',
    '000243': 'Model Deploy',
    '000244': 'Dataset Create',
    '000245': 'Validation Notifications',
    '000246': 'Validation start and completed.',
    '000247': 'E-mail',
    '000248': 'Send all notifications to my e-mail address.',
    '000249': 'Gesund.ai News notification send.',
    '000250': 'Your preferences',
    '000251': 'General',
    '000252': 'Manage your desktop notifications',
    '000253': 'Save',
    '000254': 'Dataset Upload start and finish.',
    '000255': 'Change',
    '000256': 'Remove',
    '000257': 'Multi Factor Authentication',
    '000258': 'Identity Verification',
    '000259': 'Multi Factor Authentication (MFA)',
    '000260': 'E-mail Address',
    '000261': 'Phone Number',
    '000262': 'Country',
    '000263': 'Phone Number Verification',
    '000264': 'Protect your account and transactions.',
    '000265': 'E-mail Address Verification',
    '000266': 'Login Password',
    '000267': 'Devices, Activities and Advanced Security',
    '000268': 'Device Management',
    '000269': 'Manage devices allowed to access your account.',
    '000270': 'Account Activity',
    '000271': 'Last login:',
    '000272': 'Suspicious account activity?',
    '000273': ' Disable account',
    '000274': 'Manage',
    '000275': 'more ...',
    '000276': 'Compatible Models and Datasets',
    '000277': 'Classification',
    '000278': 'Segmentation',
    '000279': 'All',
    '000280': 'Segmentation List',
    '000281': 'Augmentation',
    '000282': 'Watch',
    '000283': 'Unwatch',
    '000284': 'Default Tool',
    '000285': 'Allow Multiple Instance',
    '000286': 'Duration',
    '000287': 'Meta Data',
    '000288': 'Annotation Logs',
    '000289': 'Select Brush Eraser',
    '000290': 'Minimum Viable Subcohorts',
    '000291': 'Source',
    '000292': 'Image Format',
    '000293': 'Number of slices',
    '000294': 'Sample Information',
    '000295': 'Show Details',
    '000296': 'Hide Details',
    '000297': 'Archive Model',
    '000298': 'Warning',
    '000299': 'Open in a Playground',
    '000300': 'Rotate Right',
    '000301': 'Rotate Left',
    '000302': 'Upload Image',
    '000303': 'Micro Recall',
    '000304': 'Search',
    '000305': 'Please upload the file according the selected type.',
    '000306': 'Select Freehand Scissors',
    '000307': 'No Data',
    '000308': 'Something went wrong.',
    '000309': 'Deployment status of selected model is changed',
    '000310': 'Validation is succesfully submitted',
    '000311': 'Validation is succesfully stopped',
    '000312': 'Validation: $ is succesfully completed',
    '000313': 'Validation: $ is succesfully started',
    '000314': 'Validation is succesfully archived',
    '000315': 'Complete',
    '000316': 'Are you sure you want to change the study? You will lose all your progress.',
    '000317': 'Guest',
    '000318': 'Data Quality Control',
    '000319': 'PACS Explorer',
    '000320': 'Metadata Distribution',
    '000321': 'The image is uploaded successfully.',
    '000322': 'An error occured while uploading image.',
    '000323': 'Predicted Class',
    '000324': 'Dataset Class Distribution',
    '000325': 'Dataset Population Distribution',
    '000326': 'Most Confused Classes',
    '000327': 'Most Confused Images',
    '000328': 'Apply',
    '000329': 'Reset',
    '000330': 'True Class',
    '000331': 'Sign in with your account to start.',
    '000332': 'MFA Enabled',
    '000333': 'Macro Recall',
    '000334': 'Object Detection',
    '000335': 'If you don’t already have an account, you can instantly create one below.',
    '000336': 'Target Class',
    '000337': 'Best prediction probability:',
    '000338': 'Worst true probability',
    '000339': 'Lose by small margin',
    '000340': 'Rank Mode',
    '000341': 'Open the Study in Playground',
    '000342': 'Confidence Distribution',
    '000343': 'ROC Curve',
    '000344': 'Sample Count',
    '000345': 'Prediction Distribution',
    '000346': 'Your model has been uploaded successfully. Now you are being redirected to the model page.',
    '000347': 'Successful',
    '000348': 'Architecture',
    '000349': 'Download Artifacts',
    '000350': 'Include Model Files',
    '000351': 'Artifact downloaded successfully',
    '000352': 'An error occured while downloading',
    '000353': 'Model files are uploaded successfully',
    '000354': 'Assigned',
    '000355': 'Uploading is canceled successfully',
    '000356': 'Model Information',
    '000357': 'Fill From Previous Models:',
    '000358': 'Select a Validation',
    '000359': 'Model Summary',
    '000360': 'Model Detail',
    '000361': 'Model Outputs',
    '000362': 'New Tag',
    '000363': 'New Class',
    '000364': 'Already exist',
    '000365': 'Model Source Name',
    '000366': 'Model Source Detail',
    '000367': 'Study Table',
    '000368': 'Model Performance',
    '000369': 'Model Access Options',
    '000370': 'example@usermail.com',
    '000371': 'Add New Metadata Field',
    '000372': 'Add Category and Sample Field',
    '000373': 'Training Dataset Stats',
    '000374': 'Title',
    '000375': 'Value',
    '000376': 'Add or Select Model Source',
    '000377': 'Add New Model Source',
    '000378': 'Select Model Source',
    '000379': 'Upload Model Source Photo',
    '000380': 'Model Source created successfully',
    '000381': 'Compatible Datasets & Annotations',
    '000382': 'Model Image',
    '000383': 'Version',
    '000384': 'Model Details',
    '000385': 'Deploy',
    '000386': 'Stop',
    '000387': 'Archive',
    '000388': 'Playground',
    '000389': 'Overview',
    '000390': 'Monitoring',
    '000391': 'Container Logs',
    '000392': 'Platform Name',
    '000393': 'Model Source Details',
    '000394': 'Model deployment scheduled successfully',
    '000395': 'Model stopped successfully',
    '000396': 'We sent you an email with the link to reset your password. Please check your email!',
    '000397': 'OK',
    '000398': 'Description',
    '000399': 'Service Name',
    '000400': 'Platform Status',
    '000401': 'Model Deployment Status',
    '000402': 'Status',
    '000403': 'Deployment Id',
    '000404': 'Start',
    '000405': 'End',
    '000406': 'Download & Upload Artifacts',
    '000407': 'Compatible',
    '000408': 'days',
    '000409': 'hours',
    '000410': 'Change Password',
    '000411': 'Prediction Time',
    '000412': 'Select the readers',
    '000413': 'Hide Previous Versions',
    '000414': 'Model is archived successfully',
    '000415': 'Archived',
    '000416': 'Unarchive',
    '000417': 'Archive',
    '000418': 'Model is unarchived successfully',
    '000419': 'Generate Metrics',
    '000420': 'JPG, PNG',
    '000421': 'NIFTI',
    '000422': 'DICOM',
    '000423': 'Jpg, Png, DICOM, NIFTI, etc.',
    '000424': 'Model: $ deployed successfully',
    '000425': 'Calculated Drift',
    '000426': 'Inference Speed',
    '000427': 'Worker',
    '000428': 'Explore Predictions',
    '000429': 'Are you sure you want to deploy this model?',
    '000430': 'Are you sure you want to stop this model?',
    '000431': 'Are you sure you want to archive this model?',
    '000432': 'Are you sure you want to unarchive this model?',
    '000433': 'Are you sure you want to start validation run?',
    '000434': 'Sotmax Histograms',
    '000435': 'Precision-Recall Curve',
    '000436': 'dataset will be validated on the ',
    '000437': ' model with the settings below:',
    '000438': 'Scatter',
    '000439': 'Histogram',
    '000440': 'Copy Base64 and Augmentation properties to clipboard',
    '000441': 'Reset View',
    '000442': 'Copy',
    '000443': 'Go to Validation Metrics',
    '000444': 'Start a New Validation Run',
    '000445': 'Sample',
    '000446': 'Total',
    '000447': 'Done',
    '000448': 'Failed',
    '000449': 'Delete',
    '000450': 'Are you sure you want to delete this model?',
    '000451': 'Model is deleted successfully',
    '000452': 'View Details',
    '000453': 'Previous',
    '000454': 'Image Format',
    '000455': 'Import From Dockerhub',
    '000456': 'Dockerhub Repository',
    '000457': 'Please enter a valid value',
    '000458': 'Confidence Threshold',
    '000459': 'Show Results:',
    '000460': 'Object Counts',
    '000461': 'Undo',
    '000462': 'Redo',
    '000463': 'Advanced Security',
    '000464': 'Ground Truth',
    '000465': 'Select Field',
    '000466': 'File Name',
    '000467': 'Plot Top Misses',
    '000468': 'Measure Distance',
    '000469': 'Undo Segmentation',
    '000470': 'Redo Segmentation',
    '000471': 'Aided',
    '000472': 'Preferred Series',
    '000473': 'Please write a search keyword to find the series with fuzzy search.',
    '000474': 'Image Evaluation Form',
    '000475': 'Select a Model Type',
    '000476': 'Platform Monitoring',
    '000477': 'User Activities',
    '000478': 'Batch Table',
    '000479': 'Image',
    '000480': 'Model Name',
    '000481': 'Batch',
    '000482': 'Annotated',
    '000483': 'Time Taken',
    '000484': 'Deployment Id',
    '000485': 'Batch Run',
    '000486': 'Single Prediction',
    '000487': 'Type',
    '000488': 'Docs',
    '000489': 'User List',
    '000490': 'Role',
    '000491': 'No',
    '000492': 'User Management',
    '000493': '',
    '000494': 'User',
    '000495': 'Add new user',
    '000496': 'User/domain added successfully',
    '000497': 'Annotation Management',
    '000498': 'Dataset archived successfully ',
    '000499': 'Dataset unarchived successfully ',
    '000500': 'Please select the ',
    '000501': ' from the right menu or load the data you want to process from the ',
    '000502': ' menu.',
    '000503': 'App Version',
    '000504': 'Select Image Type',
    '000509': 'Please upload the ',
    '000505': ' Model Artifacts',
    '000506': ' to add a ',
    '000507': ' New Model',
    '000508': ' to the platform!   Then fill the form.',
    '000510': 'Delete Uploaded File',
    '000511': 'Metadata  template downloaded successfully',
    '000512': 'Cloud Platfrom',
    '000513': 'Data',
    '000514': 'Data Source',
    '000515': 'Source Details',
    '000516': '',
    '000517': 'Upload Annotation',
    '000518': 'Number of Cluster',
    '000519': '',
    '000520': '',
    '000521': '',
    '000522': '',
    '000523': '',
    '000524': 'All Datasets',
    '000525': '',
    '000526': '',
    '000527': 'Meta Data',
    '000528': 'DICOM Data',
    '000529': 'Deployment Status',
    '000530':
        'The password must contain at least 13 characters, 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character (@$!%*?&.,).',
    '000531': 'Cloud credential saved successfully',
    '000532': 'Name',
    '000533': 'Access Key Id',
    '000534': 'Secret Access Key',
    '000535': 'Add New Cloud Credentials',
    '000536': 'Cloud Credentials',
    '000537': 'Upload Dataset .zip file',
    '000538': 'Upload Dataset with url address',
    '000539': 'IMA',
    '000540': 'Add Dataset with local path',
    '000541': 'Select Option',
    '000542': 'Options',
    '000543': 'Image file types',
    '000544': 'Dataset Save Destination',
    '000545': 'Select Dataset Destination',
    '000546': 'Save to Local Storage',
    '000547': 'Save to S3 Bucket',
    '000548': 'Select Credential',
    '000549': 'Dataset zip file deleted successfully',
    '000550': 'MHD',
    '000551': 'Archive Dataset',
    '000552': 'Dataset Information',
    '000553': 'Fill From Previous Dataset',
    '000554': 'DICOM NO EXT',
    '000555': 'Compatible Models',
    '000556': 'Problem Type',
    '000557': 'Select Source',
    '000558': 'Dataset Details',
    '000559': 'Select Data Source',
    '000560': 'Add New Data Source',
    '000561': '',
    '000562': 'Dataset Access Options',
    '000563': 'Upload Source Photo',
    '000564': 'Source Name',
    '000565': 'Source Detail',
    '000566': 'Dataset Cover Image',
    '000567': '',
    '000568': '',
    '000569': ',',
    '000570': '',
    '000571': '',
    '000572': '',
    '000573': '',
    '000574': '',
    '000575': 'DCM',
    '000576': '',
    '000577': 'Select a File Type',
    '000578': 'Import with URL',
    '000579': '',
    '000580': '',
    '000581': '',
    '000582': '',
    '000583': '',
    '000584': '',
    '000585': 'Upload Metadata',
    '000586': 'Source created successfully',
    '000587': 'Last Updated',
    '000588': 'Your dataset has been stored successfully. Now you are being redirected to the dataset page.',
    '000589': 'Last Activity',
    '000590': '',
    '000591': 'Please select file type first',
    '000592': '',
    '000593': '',
    '000594': 'Go to Playground',
    '000595': 'Start a Validation',
    '000597': '$1 model were failed during stopping process.',
    '000598': '',
    '000599': '',
    '000600': 'Folder Name',
    '000601': 'URI',
    '000602': '',
    '000603': 'Please enter Local Dataset Path',
    '000604': 'Local Dataset Path',
    '000605': '',
    '000606': 'Dataset uploaded successfully',
    '000607': 'Filter',
    '000608': 'Hide Public Datasets',
    '000609': 'Recent Activities (Last 24 Hours)',
    '000610': 'There is no recent activity',
    '000611': 'Are you sure to archive the $ validation ',
    '000612': '',
    '000613': 'Do you confirm the deletion of this credential?',
    '000614': 'Models',
    '000615': 'This dataset requires live connection for platform services',
    '000616': 'Unarchive Dataset',
    '000617': 'Gesund.ai Dataset Hub ',
    '000618': 'Validation Status',
    '000619': 'Gesund.ai Model Hub ',
    '000620': 'A CRO platform for clinical-grade AI',
    '000621': 'Hide Public Models',
    '000622': 'Delete Dataset',
    '000623': 'Dataset is deleted successfully',
    '000624': 'Are you sure you want to archive this dataset?',
    '000625': 'Are you sure you want to unarchive this dataset?',
    '000626': 'Are you sure you want to delete this dataset?',
    '000627': 'Cloud credential deleted successfully',
    '000628': 'Download Metadata',
    '000629': 'Validation Stats',
    '000630': 'Single Prediction Stats',
    '000631': 'Total Usage',
    '000632': 'Total Number of User',
    '000633': 'Show Compatibles',
    '000634': 'This model is incompatible with selected dataset',
    '000635': 'Category',
    '000640': 'This dataset has no compatible model',
    '000641': 'This dataset is incompatible with selected model',
    '000642': 'Delete Model',
    '000643': 'Unarchive Model',
    '000645': 'Views',
    '000646': 'Pathology',
    '000647': 'Procedure',
    '000648': 'Show Clipboard',
    '000649': 'Dataset Summary',
    '000650': 'Please Select Model Source',
    '000651': 'Please enter Model Source Details',
    '000652': 'Please enter Model Source Name',
    '000653': 'Please enter Model Source Website',
    '000654': 'Please enter Model Source E-mail',
    '000655': 'Default Window Level',
    '000656': 'How to Upload Dataset ?',
    '000657': 'How to Upload Model ?',
    '000658': '',
    '000659': '',
    '000660': 'Dataset has been edited successfully',
    '000661': 'Back to Dataset Details',
    '000662': 'Model Training',
    '000663': 'Archived Datasets',
    '000664': 'Archived Models',
    '000665': 'Show Deployed Models',
    '000666': 'Show Dicom Datasets',
    '000667': 'Popularity Ascending',
    '000668': 'Popularity Descending',
    '000669': 'Date Latest',
    '000670': 'Date Oldest',
    '000671': 'Popularity & Date',
    '000672': 'Clear Filters',
    '000673': 'Search Image',
    '000674': '',
    '000675': 'Generate Blind Spot Analysis',
    '000676': 'Select a binning.',
    '000677': 'quantile',
    '000678': 'Or',
    '000679': '',
    '000680': 'Preemptive option updated successfully',
    '000681': 'Preemptive Model',
    '000682': 'Set Pre-emptive Status',
    '000683': 'Pause Duration',
    '000684': 'minutes',
    '000685': 'Please select idle timeout',
    '000686': 'Please stop model before deleting',
    '000687': 'Please stop model before archiving',
    '000688': 'Please stop model for editing',
    '000689': 'Please deploy model first',
    '000690': '',
    '000691': 'Similar Datasets',
    '000692': 'There is no similiar dataset',
    '000693': 'Explain Result',
    '000694': 'Endpoint Registration',
    '000695': 'Endpoint (url)',
    '000696': 'Select Modality',
    '000697': 'Similarity Score',
    '000698': '',
    '000699': 'All models stopped',
    '000700': '$1 model successfully stopped',
    '000701': 'Upload New Annotation',
    '000702': 'Image Name',
    '000703': 'Edit',
    '000704': 'Set as Default',
    '000705': 'Annotation is set as default successfully',
    '000706': 'Confirm',
    '000707': '',
    '000708': 'Annotation Name',
    '000709': 'You can add your Cloud Credentials in order to access the datasets / models located in your private buckets',
    '000710': 'Please enter a unique name',
    '000711': 'Annotation File',
    '000712': 'Please select a column for file name',
    '000713': 'Please select a column for ground truth',
    '000714': 'Changes are not saved!',
    '000715': '',
    '000716': 'Annotation file deleted successfully',
    '000717': 'Are you sure want to save new Annotation',
    '000718': 'Annotation saved successfully',
    '000719': 'Are you sure want to save edited annotation $1, you edited $2 images',
    '000720': 'Your changes are not saved! You will lose the changes!',
    '000721': 'Clone From Previous Annotation',
    '000722': 'Select Annotation',
    '000723': 'AP@10',
    '000724': 'AP@50',
    '000725': 'AP@75',
    '000726': 'AP@[.50,.95]',
    '000727': 'Please draw bounding box to get prediction',
    '000728': 'mIoU',
    '000729': 'Performance by IoU Threshold',
    '000730': 'Object Counts',
    '000731': 'You can not remove the default annotation',
    '000732': 'Are you sure want to change access type of dataset $1 to $2',
    '000733': 'Access Granted',
    '000734': 'The Access Option updated successfully',
    '000735': 'Are you sure you want to update the permission list?',
    '000736': 'Image Stream Stats',
    '000737': 'Share Link',
    '000738': 'Validation Link copied to clipboard',
    '000739': 'Download Raw Result',
    '000740': 'Download',
    '000741': 'Interactive Viewer',
    '000742': 'Window Level',
    '000743': 'Prefetch Images',
    '000744': 'Download Raw Image',
    '000745': 'Edit Controls',
    '000746': 'Dicom Controls',
    '000747': 'Zoom',
    '000748': 'Length',
    '000749': 'Angle',
    '000750': 'Raw Result',
    '000751': 'Ellipses',
    '000752': 'Rectangle',
    '000753': 'Pan',
    '000754': 'Metadata',
    '000755': 'Upload New Metadata',
    '000756': 'Metadata Management',
    '000757': 'Export as CSV',
    '000758': 'Clone From Metadata',
    '000759': 'Select Metadata',
    '000760': 'Metadata File',
    '000761': 'Metadata Name',
    '000762': 'Column Name',
    '000763': 'Field Name',
    '000764': 'Field Type',
    '000765': 'Include Validation',
    '000766': 'String',
    '000767': 'Number',
    '000768': 'Boolean',
    '000769': 'Date',
    '000770': 'Free Text',
    '000771': 'Please input field name',
    '000772': 'Add',
    '000773': 'The model returned non-standart output. Click to view raw JSON result.',
    '000774': 'No dataset found with the id: $1',
    '000775': 'Upload From CSV File',
    '000776': 'Get From DICOM Metadata',
    '000777': '',
    '000778': 'How do you want to continue?',
    '000779': 'You can create metadata from multiple strategies.',
    '000780': 'What fields do you want to include?',
    '000781': 'Select All',
    '000782': 'Please upload the metadata as a CSV file',
    '000783': 'Select the image name column',
    '000784': 'There is no DICOM metadata for this dataset',
    '000785': 'Metadata saved successfully',
    '000786': 'You can not remove the default metadata',
    '000787': 'Metadata file deleted successfully',
    '000788': 'Are you sure want to delete the metadata: $',
    '000789': 'Are you sure want to save new Metadata',
    '000790': 'Are you sure want to save edited metadata $1, you edited $2 images',
    '000791': 'Are you sure want to the metadata $1 of dataset $2 with the as default?',
    '000792': 'Polygon',
    '000793': 'Update User',
    '000794': 'Show Ground Truth',
    '000795': 'Clinical Text',
    '000796': 'Clinical Entities',
    '000797': 'Get Entities',
    '000798': 'Series Number',
    '000799': 'Show Dicoms',
    '000800': 'Count',
    '000801': 'Get Comparison',
    '000802': 'Validation Performance',
    '000803': 'Genome Sequence Analysis',
    '000804': 'Sample - Total',
    '000805': 'Sample - Done/Failed',
    '000806': 'Total / Done / Failed',
    '000807': '',
    '000808': 'Up',
    '000809': 'Gesund.ai Terms and Conditions',
    '000810': 'Metadata is set as default successfully',
    '000811': 'Low network speed',
    '000812': 'Offline',
    '000813': 'Annotation',
    '000814': 'AI Assistance',
    '000815': 'Home',
    '000816': 'Full Page',
    '000817': 'Annotation Count',
    '000818': 'Instance Activity',
    '000819': 'Complete Project',
    '000820': '',
    '000821': 'Model Configuration',
    '000822': '',
    '000823': '',
    '000824': 'Validation is not found. You may not have permission to access this validation.',
    '000825': 'Extracted entities running in the background',
    '000826': 'Select Default Fields',
    '000827': 'Annotation is saved successfully',
    '000828': 'Clear',
    '000829': 'No object found',
    '000830': 'Start Training',
    '000831': 'Threshold Brush',
    '000832': 'Drag Probe',
    '000833': 'Create New Project',
    '000834': '',
    '000835': '',
    '000836': '',
    '000837': '',
    '000838': '',
    '000839': '',
    '000840': 'Description',
    '000841': 'Project is not found or you may not have permission to access this project. Please contact your administrator.',
    '000842': '',
    '000843': 'Create',
    '000844': 'Annotation project is created successfully',
    '000845': 'Project Information',
    '000846': 'Distribution',
    '000847': 'Maatthews CC',
    '000848': 'Blind Spot Analysis',
    '000849': '',
    '000850': 'Performance',
    '000851': 'Generate Subcohorts',
    '000852': '',
    '000853': '',
    '000854': '',
    '000859': 'Validation: $ is succesfully created as subcohort',
    '000860': '',
    '000861': 'The Validation has not any metadata',
    '000862': 'Subcohort Distribution',
    '000863': 'Subcohort Validation',
    '000864': 'Metadata Filters',
    '000865': 'Metrics Generated',
    '000866': 'Blind Spot Analysis Generated',
    '000867': '',
    '000868': 'Your session is expired. Do you want to continue?',
    '000869': '',
    '000870': '',
    '000871': 'Sample Size Distribution',
    '000872': 'Index',
    '000873': 'Compare with Parent Validation',
    '000874': 'Parent Validation',
    '000875': 'Validation Analysis',
    '000876': 'Base Validation',
    '000877': 'Compared to',
    '000878': 'Select Validation',
    '000879': 'Continue with Microsoft',
    '000880':
        'Deep dive in to the validation / batch run, discover meaningful insights and hidden nuances. Analyze model predictions, cross reference model predictions and Dataset, filter model prediction using metadata and determine its significance using standard metrics to make better sense of model predictions and quality of data. Compare two validation to determine latent features of dataset that helps you in understanding the nature of the dataset. Find all or any blind spots / sub cohorts in your datasets by analyzing relationship between metadata and model prediction using standard metrics.',
    '000881': 'User / Domain',
    '000882': 'Domain',
    '000883': 'The user / domain removed from verification list',
    '000884': 'Verification List',
    '000885': 'Round',
    '000886': 'Blind Spot Analysis Result is not found',
    '000887': 'Min Cohort Size',
    '000888': 'Metadata Selection',
    '000889': 'Blind Spot Analysis is being generated.',
    '000890': 'Select Class',
    '000891': 'Missed Subcohorts',
    '000892': 'Lower Bound',
    '000893': 'Max Combinations',
    '000894': 'Calculate Combinations',
    '000895': 'Combinations will be picked randomly',
    '000896': 'Create User',
    '000897': 'Verify User',
    '000898': 'Send Random Password',
    '000899': 'Create Password',
    '000900': 'Export Pdf',
    '000901': 'Robust Validation Run',
    '000902': 'Augmentation Techniques',
    '000903': '',
    '000904': '',
    '000905': 'Dataset',
    '000906': 'Images',
    '000907': 'Dataset Curation',
    '000908': 'Dataset Filter',
    '000909': 'Image Filter',
    '000910': 'Dataset Create',
    '000911': 'Row Count',
    '000912': 'Skip',
    '000913': 'Filtered Image',
    '000914': '',
    '000915': 'Batch name is already exist!',
    '000916': 'Add Meta Filter',
    '000917': 'Curated Datasets',
    '000918': 'Filter Applied Successfully',
    '000919': 'Selected Images',
    '000920': 'Select From Study List',
    '000921': 'Dataset Created',
    '000922': 'Created Image',
    '000923': 'Select Batch',
    '000924': 'Next',
    '000925': 'Hide Empty Default Metadata',
    '000926': 'Default Metadata',
    '000927': 'Curated Dataset Created Successfully',
    '000928': 'At least one dataset should be selected',
    '000929': 'Selected Dataset Is Not Compatible With Selected Model',
    '000930': 'Selected Dataset And Model Problem Types Are Incompatible',
    '000931': 'Selected Dataset And Model Class Mappings Are Not Equal',
    '000932': 'Select Dataset For Generate Metrics',
    '000933': 'Select Model For Generate Metrics',
    '000934': 'Apply filters or skip this step!',
    '000935': 'Full Screen',
    '000936': 'Exit Full Screen',
    '000937': 'Edit Validation',
    '000938': 'Validation Name',
    '000939': 'Validation Access Option',
    '000940': 'Save',
    '000941': 'Validation has been edited successfully',
    '000942': 'Back To Validation Summary',
    '000943': 'Combination',
    '000944': 'Create Subcohort',
    '000945': 'Validation Samples',
    '000946': 'Help Center',
    '000947': '',
    '000948':
        "Take a look at the overview of existing Models, Datasets and Validation descriptive statistics. Explore historical validation runs and monitor recently scheduled validation job's execution. Visit the page to know more on it",
    '000949': '',
    '000950':
        'Schedule a validation / batch run using desired deployed Model and its compatible dataset. Explore recently added Validation Runs, monitor current Validation Run Job progress. Check out the page and start the first step of the process of understand your model and dataset.',
    '000951':
        'Explore the model catalogue containing broad variety of computer vision models from Radiology or other clinical domains. Deploy the model with the click of a button and explore the model performance using the well-designed standard validation metrics by scheduling a Validation run or check how the model performs in playground page.',
    '000952':
        'Visit the page to upload any desired model of your choice, and add it to the model catalogue. You can choose to deploy the model with the click of a button and explore the model performance using the well-designed standard validation metrics by scheduling a Validation run or check how the model performs in playground page.',
    '000953':
        'View the list of inactive and archived models that are not in use. You can let it sleep here or restore it back into action.',
    '000954':
        'Explore the dataset catalogue containing broad variety of clinical domains. Checkout the metadata associated with these standard datasets from reliable sources for better understanding of the dataset and its corresponding model performance.',
    '000955':
        'Visit the page to upload any desired dataset of your choice, and add it to the dataset catalogue. You can choose to make the dataset private or publicly available. You can choose to schedule validation run on the upload dataset using a deployed model or explore single images in the playground page.',
    '000956':
        'Handpick sub cohorts and curate a dataset of your liking. Use this customized dataset for improving the robustness of your model performance and derive new validation metrics.',
    '000957':
        'View the list of inactive and archived dataset that are not in use. You can let it sleep here or restore it back into action.',
    '000958': 'Playground Tool',
    '000959':
        'Test the model performance and its prediction on single or small subset of images in the playground mode. You can make predictions on slices or series of images, view metadata associated to it, add augmentations to the images and review the model predictions.',
    '000960': 'Annotation Tool',
    '000961':
        'Create a project using existing or uploaded dataset and tag custom annotation to the images for improving the quality of the dataset and model performance.',
    '000962': '',
    '000963': 'Access user instructions about what to do when getting started.',
    '000964': 'XLSX',
    '000965': 'TSV',
    '000966': 'Export Metrics',
    '000967': 'Select Charts To Export As Pdf',
    '000968': 'Hi',
    '000969': 'You are about to create',
    '000970': 'subcohort validation run for the selected validation.',
    '000971': 'Do you confirm ?',
    '000972': 'You will be logged out after',
    '000973': 'seconds',
    '000974':
        'This charts indicate the utilisations that will help you see the current load of the container that the selected model runs in. Please consider this while starting a new validation run.',
    '000975': 'Dataset Filtering & Select',
    '000976': 'Please select a field',
    '000977': 'Select field or remove filter',
    '000978': 'Similarity',
    '000979': 'Show Near Duplicates',
    '000980': 'Near Duplicates',
    '000981': 'CSV',
    '000982': 'Training',
    '000983': 'Pdf export started',
    '000984': 'Pdf exported successfully',
    '000985': 'Select charts to view',
    '000986': 'Edit View',
    '000987': 'Save and Close',
    '000988': 'Filter Charts',
    '000989': 'Dataset Distribution Patient Characteristics',
    '000990': 'Dataset Distribution Imaging Characteristics',
    '000991': 'Model Performance Class Comparison',
    '000992': 'Model Performance Patient Demographics Comparison',
    '000993': 'Model Performance Imaging Comparison',
    '000994': 'Validation Summary',
    '000995': 'Excel',
    '000996': 'Pdf',
    '000997': 'Select Characteristic',
    '000998': 'Characteristic',
    '000999': 'patient',
    '001000': 'User is already exist',
    '001001': 'You have successfully updated your notification preferences.',
    '001002': 'Are you sure you want to change phone verification?',
    '001003': 'To protect your account, adding and authorizing a new device will be disabled for 24 hours after this change.',
    '001004': 'You have successfully updated your profile.',
    '001005': 'Change Password',
    '001006': 'You have successfully updated user info.',
    '001007': 'Model Source Files',
    '001008': 'Image deleted successfully',
    '001009': 'is not registered user.',
    '001010': 'There is no source file for this model.',
    '001011': 'File saved successfully.',
    '001012': 'You are not allowed to see the source files.',
    '001013': 'Grid Layout',
    '001014': 'Stack Scroll',
    '001015': 'Cine',
    '001016': 'Erase Tool',
    '001017': 'Text Marker',
    '001018': '',
    '001019': 'Scale Overlay',
    '001020': '(Owner)',
    '001021': 'Result',
    '001022': 'Project Name',
    '001023': 'Configuration',
    '001024': 'Add Team User',
    '001025': 'Studies',
    '001026': 'Assignment',
    '001027': 'Progress',
    '001028': 'Edit Annotation Project',
    '001029': 'Project Details',
    '001030': 'There is nothing to see yet.',
    '001031': 'Batch Size',
    '001032': 'Batch Name',
    '001033': 'Reject',
    '001034': 'ZOOM',
    '001035': 'Submit/Save',
    '001036': 'Object Detail',
    '001037': 'Remove Annotation',
    '001038': 'Hide Annotation',
    '001039': 'Deselect Tool',
    '001040': 'Zoom In/Out',
    '001041': 'GENERAL',
    '001042': 'Keyboard Shortcuts',
    '001043': 'Instance Segmentation',
    '001044': 'Semantic Segmentation',
    '001045': 'Are you sure you want to delete this image?',
    '001046': 'Completed Projects',
    '001047': 'Error reported successfully.',
    '001048': 'Reported Messages',
    '001049': 'Error Message',
    '001050': 'Info Message',
    '001051': 'Success Message',
    '001052': 'Fav',
    '001053': 'Are you sure want to the annotation file',
    '001054': 'of dataset',
    '001055': 'with the as default?',
    '001056': 'Are you sure you want to delete the annotation',
    '001057': 'file of the dataset named',
    '001058': 'Restart',
    '001059': 'Are you sure to restart the $ validation ',
    '001060': 'Validation: $ is successfully restarted ',
    '001061': 'Are you sure to delete the $ validation ',
    '001062': 'Validation is deleted successfully ',
    '001063': 'Delete Validation ',
    '001064': 'Are you sure to favorite the blind spot analysis round ',
    '001065': 'Blind Spot Analysis round is successfully favorited',
    '001066': 'Edit Blind Spot Analysis Round',
    '001067': 'Round Name',
    '001068': 'Blind Spot Analysis round is successfully unfavorited',
    '001069': 'Are you sure to unfavorite the blind spot analysis round ',
    '001070': 'with $ id ',
    '001071': 'Blind Spot Analysis Round Name edited successfully. ',
    '001072': 'Image/Study Id',
    '001073': 'Hide Prediction Section',
    '001074': 'Show Prediction Section',
    '001075': 'Compatibility Issue',
    '001076': 'Gesund.ai Platform only supports screen resolution above 768 pixels.',
    '001077': 'Please use a larger screen to access the platform.',
    '001078': 'Hide Annotation Details',
    '001079': 'Show Annotation Details',
    '001080': 'Hide Image Gallery',
    '001081': 'Show Image Gallery',
    '001082': 'Free Hand Drawing',
    '001083': 'Robust',
    '001084': 'Add Batch',
    '001085': 'Hide Segments [A]',
    '001086': 'Re-run',
    '001087': 'Show/Hide Segmentation',
    '001088': 'Annotator can work on the tasks that are assigned.',
    '001089': "Reviewer can view and review other annotator's studies.",
    '001090': 'Manager can edit the project configuration.',
    '001091': 'Please enable interactive viewer to use these controls.',
    '001092': 'pdfHeader',
    '001093': 'pdfDetails',
    '001094': 'footer',
    '001095': 'Validation Details',
    '001096': 'Dataset:',
    '001097': 'Model:',
    '001098': 'Annotation Id:',
    '001099': 'Anatomy:',

    '001100': 'Project is removed successfully',
    '001101': 'Model-Dataset Class Matching',
    '001102': 'Initial Polygon Simplify',
    '001103': 'Polygon Simplify',
    '001104': 'Simplify All',
    '001105': 'Below Lower Bound',
    '001106': 'Open Metadata Importance',
    '001107': 'Metadata Importance',
    '001108': 'Include',
    '001109': 'Please include at least one metadata',
    '001110': 'Output Mapping',
    '001111': 'Get Output',
    '001112': 'You have successfully updated the model: $.',
    '001113': 'Are you sure you want to delete this round?',
    '001114': 'Mismatched Predictions',
    '001115': 'Compare with',
    '001116': 'Validation view is saved successfully',
    '001117': 'Documentation',
    '001118': 'Annotation Projects',
    '001119': 'Gesund.ai Annotation Hub',
    '001120': 'Create New Project',
    '001121': 'Sampling Strategy',
    '001122': 'Random',
    '001123': 'Sequantial',
    '001124': 'Get Prediction',
    '001125': 'Instructions',
    '001126': 'Select Team Members',
    '001127': 'Annotator',
    '001128': 'Reviewer',
    '001129': 'Manager',
    '001130': 'Platform Settings',
    '001131': 'AI Assistance Enabled',
    '001132': 'Select Models',
    '001133': '',
    '001134': 'Please select a class',
    '001135': 'Team',
    '001136': 'Assignment',
    '001137': 'Show Unassigned',
    '001138': "You don't have permission to access this study.",
    '001139': 'Add new annotator',
    '001140': 'Annotation project updated successfully',
    '001141': 'Study',
    '001142': 'Clear empty fields',
    '001143': 'Labeling Strategy',
    '001144': 'Single',
    '001145': 'Multiple',
    '001146': 'Update Dateset',
    '001147': 'Add New Problem Configuration',
    '001148': 'Simultaneous',
    '001149': 'Discrete',
    '001150': 'Annotation Mode',
    '001151': 'Series',
    '001152': 'You have no permission to use this role.',
    '001153': 'Options',
    '001154': 'Select a Validation Run',
    '001155': 'Show compatible validation runs',
    '001156': 'Your platform activation will be expired in $. Please contact your administrator.',
    '001157': 'Image',
    '001158': 'You can create multiple batches by size or create batch by selecting studies from table.',
    '001159': 'Add New Measurement Element',
    '001160': 'Extra Annotation Template',
    '001161': 'Add New Set Item',
    '001162': 'Measurement Set',
    '001163': 'Object List',
    '001164': 'Unit',
    '001165': 'Value List',
    '001166': 'Open Measurements',
    '001167': 'Measurements',
    '001168': 'This measurement is taken from a different image. Do you want to go to this image?',
    '001169': 'Go to Image',
    '001170': 'There is no completed validation currently. Please start a new validation or change the project.',
    '001171': 'Contour Detection',
    '001172': 'Series View',
    '001173': 'Selections',
    '001174': 'Image List',
    '001175': 'Organization',
    '001176': 'Display Name',
    '001177': 'Show Predictions',
    '001178': 'Are you sure you want to download this dataset?',
    '001179': 'Yes',
    '001180': 'Are you sure you want to run dataset analysis?',
    
    '001200': 'Allow Download',
    '001201': 'Project Name',
    '001202': 'Google Cloud Platform',
    '001203': 'Role',
    '001210': 'Dataset Analysis',
    '001211': 'Image Similarity',
    '001212': 'Outlier Images',
    '001213': 'Image Statistics',
    '001214': 'Noisy Labels',
    '001215': 'Go to Dataset Analysis',
    '001216': 'View Dataset Detail',
    '001217': 'Dataset Images Explore',
    '001218': 'Please select an image from the left side.',
    '001219': 'Noisy Label',
    '001220': 'Similar Images Comparison',
    '001221': 'Unique Images',
    '001222': 'Select Statistics Type',
    '001223': '',
    '001224': 'Analysis Generated',
    '001225': 'Total Number of Outlier Images',
    '001226': 'Run Dataset Analysis',
    '001227': 'Similar Image GT',
    '001228': 'Noisy Labels Comparison',
    '001229': 'Add Model with S3 Url Adress',
    '001230': 'Please type the S3 Url Adress',
    '001231': 'Import from S3',
    '001232': 'Dataset Selection',
    '001233': 'Model Selection',
    '001234': 'Previous Validations',
    '001235': 'Activate Project',
    '001236': 'Projects',
    '001237': 'Gesund.ai Project Hub',
    '001238': 'Project is created successfully',
    '001239': 'Add Dataset',
    '001240': 'Add Model',
    '001241': 'Select Datasets',
    '001242': 'Project updated successfully',
    '001243': 'Deactivate Project',
    '001244': 'Project activated successfully',
    '001245': 'Project deactivated successfully',
    '001246': 'Project Access Options',
    '001247': 'Project deleted successfully',
    '001248': 'Delete Project',
    '001249': 'Are you sure you want to delete this project?',
    '001250': 'Validations',
    '001251': 'Activated',
    '001252': 'Select Project',
    '001253': 'Project changed successfully',
    '001254': 'Are you sure you want to change this project?',
    '001255': '',
    '001256': 'Add Validation',
    '001257': 'Select Validations',
    '001258': 'No project found with the id: $1',
    '001259': 'Are you sure you want to activate this project?',
    '001260': 'Are you sure you want to deactivate this project?',
    '001261': 'External Model Registration',
    '001262': 'External Model URL',
    '001263': 'Number of Studies',
    '001264': 'Dataset Type',
    '001265': 'Select Study',
    '001266': 'External Model',
    '001267': 'Vendor Model',
    '001268': 'System Utils',
    '001269': 'Selected',
    '001270': 'Add a custom model to the platform, with architecture and framework',
    '001271': 'Add a custom model from dockerhub',
    '001272': 'Add a custom model in zip format using s3 url',
    '001273': 'Register an endpoint for deployed model on another platform',
    '001274': 'Upload client code logic containing endpoint for deployed model on another platform',
    '001275': 'Average Metrics',

    '001280': 'External Model Validation',
    '001281': 'Select Dataset',
    '001282': 'Select Model',
    '001283': 'Select Study',
    '001284': 'External Model Validation List',
    '001285': 'View PDF',
    '001286': 'Study Result',
    '001287': 'Models Filtering & Select',
    '001288': 'Clear Selected',
    '001300': 'imaging',
    '001301': 'Cohort Size Analysis (Largest Cohorts)',
    '001302': 'Datasets Filtering & Select',
    '001303': 'Annotations Filtering & Select',
    '001304': 'Select Round',
    '001305': 'Export Report',
    '001306': 'Excel exported successfully',
    '001307': 'Landscape View',
    '001308': 'Cohort Size Analysis (Smallest Cohorts)',
    '001309': 'Total Genomes',
    '001310': 'GC %',
    '001311': 'Image View',
    '001312': 'Cohort Size',
    '001313': 'Isoelectric Point',
    '001314': 'Hide Empty Tables',
    '001315': 'Exclude tables:',
    '001316': 'Exclude metadata fields:',
    '001317': 'Aromaticity',
    '001318': 'Activation Token',
    '001319': 'Please input activation token',
    '001320': 'Enter activation token',
    '001321': 'Activation token expired. Please contact with Gesund.ai',
    '001322': 'Series Id',
    '001323': 'NPV',
    '001324': 'Validate',
    '001325': 'AR@max=100',
    '001326': 'AR@max=10',
    '001327': 'AR@max=1',
    '001328': 'Instance Count',
    '001329': 'Stop Models',
    '001330': 'Lift Chart',
    '001331': 'No model found with the id: $1',
    '001332': 'Word',
    '001333': 'Series Count',
    '001334': 'Please input your password!',
    '001335': 'Please confirm your password!',
    '001336': 'The two passwords that you entered do not match!',
    '001337': 'Please input your current password!',
    '001338': 'Confirm your new password',
    '001339': 'Please enter your password!',
    '001340': 'Are you sure you want to stop models?',
    '001341': 'Summary Stats',
    '001342': 'Distance',
    '001343': 'Dataset Analysis Stats',
    '001344': 'Metadata Distribution',
    '001345': 'Please enter password!',
    '001346': 'Diversity Index',
    '001347': 'Select Annotation',
    '001348': 'Pre-annotation process is not successful. Please try again.',
    '001349': 'Select metadata to see chart data',
    '001350': 'Dice',
    '001351': 'IOU',
    '001352': 'pAccs',
    '001353': 'meanIOU',
    '001354': 'AUC',
    '001355': 'Kappa',
    '001356': '',
    '001357': 'ARI',
    '001358': 'Detailed Metrics',
    '001359': '',
    '001360': 'mAUC',
    '001361': 'mKAPPA',
    '001362': 'SHD',
    '001363': 'AHD',
    '001364': 'mIoU',
    '001365': 'mfwIoU',
    '001366': 'PA',
    '001367': 'CE',
    '001368': 'VS',
    '001369': 'MCCC',
    '001370': 'Intersection Distribution',
    '001371': 'Quick Links',
    '001372': 'Number Of Minority Sub Cohort Groups',
    '001373': 'Number Of Sub Cohorts',
    '001374': 'Percent Of Sub Cohorts',
    '001375': 'Number Of Patients Relative To Total Validation',
    '001376': 'Class',
    '001377': 'Column Filters',
    '001378': 'Model Performance Class Comparison (Overall)',
    '001379': 'Diversity Index Filter',
    '001380': 'Are you sure you want to update this study/image?',
    '001381': 'Total Validation',
    '001382': 'In Progress / Completed Runs',
    '001383': 'Models Used for Validation',
    '001384': 'Most Used Models',
    '001385': 'Datasets Used for Validation',
    '001386': 'Most Used Datasets',
    '001387': "Something went wrong. Couldn't connect to server.",
    '001388': 'Metadata Two Variable Comparison (Best Performers)',
    '001389': 'Metadata Two Variable Comparison (Worst Performers)',
    '001390': 'Metadata One Variable Comparison (Best Performers)',
    '001391': 'Metadata One Variable Comparison (Worst Performers)',
    '001392': 'Validation is successfully unfavorited',
    '001393': 'Unfavorite',
    '001394': 'Favorite',
    '001395': 'Validation is successfully favorited',
    '001396': 'Are you sure to favorite the $ validation?',
    '001397': 'Are you sure to unfavorite the $ validation?',
    '001398': 'Successfully copied to clipboard',
    '001399': 'Export Tables',
    '001400': 'Highlighted Metrics',
    '001401': 'Reset Filters',
    '001402': 'Back to Project Details',
    '001403': 'User Mode',
    '001404': 'Please select a value',
    '001405': 'Approve',
    '001406': 'Label',
    '001407': 'Annotation is updated successfully',
    '001408': 'Analytics',
    '001409': 'Completed / Total Assignment',
    '001410': 'Review Status',
    '001411': 'Start Date',
    '001412': 'Last Update Date',
    '001413': 'Go To Study',
    '001414': 'Do you want to save the changes made to',
    '001415': 'Brush Radius',
    '001416': 'Annotator Summary',
    '001417': 'Study Completed',
    '001418': 'Save Study',
    '001419': 'Segment Tools [S]',
    '001420': 'Remove Predictions',
    '001421': 'No validation found with selected model and dataset.',
    '001422': 'You can create new validation from ',
    '001423': ' page.',
    '001424': 'start new validation',
    '001425': 'measurements are missing',
    '001426': 'Some measurements changes made on completed annotation.',
    '001427': 'Are you sure you want to remove pre-annotations?',
    '001428': 'Are you sure you want to apply pre-annotations?',
    '001429': 'Pre-annotations removed successfully.',
    '001430': 'Delete Annotation Project',
    '001431': 'Annotation project is completed successfully',
    '001432': 'Image/Slice',
    '001433': 'Position',
    '001434': 'Pre-annotations applied successfully.',
    '001435': 'Show',
    '001436': 'Show User Annotations',
    '001437': 'Are you sure you want to complete this project?',
    '001438': 'Pre-Annotation',
    '001439': 'Annotation export started. This process may take time to complete.',
    '001440': 'Export COCO',
    '001441': 'Export CSV',
    '001442': 'Show Labels',
    '001443': 'Pixel Classification Training',
    '001444': 'Are you sure you want to train Pixel Classification model?',
    '001445': 'Train',
    '001446': 'Select Reader',
    '001447': 'Study Count',
    '001448': 'Readers',
    '001449': 'Please draw point to get prediction',
    '001450': 'Image Size',
    '001451': 'Backbone',
    '001452': 'Labeling Configuration',
    '001453': 'Validation Size',
    '001454': 'Training Size',
    '001455': 'Validation Split',
    '001456': 'Optimizer',
    '001457': 'Train Job',
    '001458': 'Pre Dense Layer',
    '001459': 'Epochs',
    '001460': 'Word exported successfully',
    '001461': 'Export JSON',
    '001462': 'Pre Annotation Applied',
    '001463': 'Are you sure you want to remove this user?',
    '001464': 'User removed successfully',
    '001465': 'Are you sure you want to remove this user / domain from verification list?',
    '001466': 'View Raw Result',
    '001467': 'Select Labeling',
    '001468': 'Model is created successfully',
    '001469': 'Review',
    '001470': 'To Do',
    '001471': 'Height',
    '001472': 'Width',
    '001473': 'Metrics Distribution',
    '001474': 'Model Training: $ is succesfully completed',
    '001475': 'Model Training: $ is succesfully started',
    '001476': 'Start Training',
    '001477': 'Are you sure you want to start model training?',
    '001478': 'Model will be trained with the ',
    '001479': ' dataset with the settings above.',
    '001480': 'Time Spent',
    '001481': 'Nothing to see here yet!',
    '001482': 'Encoder Backbone',
    '001483': 'Segmentation Backbone',
    '001484': 'Please make a model selection in order to wide this section.',
    '001485': 'Trained',
    '001486': 'Convert To Model',
    '001487': 'Are you sure you want to finalize this training job and convert to model?',
    '001488': 'Model created successfully.',
    '001489': 'Halt',
    '001490': 'Begin',
    '001491': 'Training Metrics',
    '001492': 'Delete Training Job',
    '001493': 'Train Job deleted successfully',
    '001494': 'Are you sure to delete the $ train job',
    '001495': 'Model mode updated successfully',
    '001496': 'Edit Train Job',
    '001497': 'Train Job edited successfully',
    '001498': 'Train Job is successfully favorited',
    '001499': 'Are you sure to favorite the $ train job?',
    '001500': 'DSC Enhanced',
    '001501': 'DSC Sets',
    '001502': 'DSC CM',
    '001503': 'DSC',
    '001504': 'IoU Sets',
    '001505': 'IoU CM',
    '001506': 'Sensitivity Sets',
    '001507': 'Sensitivity CM',
    '001508': 'Specificity Sets',
    '001509': 'Specificity CM',
    '001510': 'Precision Sets',
    '001511': 'Precision CM',
    '001512': 'Accuracy Sets',
    '001513': 'Accuracy CM',
    '001514': 'MCC',
    '001515': 'MCC Normalized',
    '001516': 'MCC Absolute',
    '001517': 'Sort By',
    '001518': 'fwIoU',
    '001519': 'Labeling Configurations',
    '001520': 'Are you sure to unfavorite the $ train job?',
    '001521': 'Train Job is successfully unfavorited',
    '001522': 'Train Job is successfully converted to model',
    '001523': 'No studies assigned',
    '001524': 'Inference Explorer',
    '001525': 'Checkpoint Save Mode',
    '001526': 'Learning Rate',
    '001527': 'Retrain',
    '001528': 'Retrain Model',
    '001529': 'Audit Logs',
    '001530': 'Session List',
    '001531': 'Export YOLO',
    '001532': 'Stop Validations',
    '001533': 'Are you sure you want to stop selected validations?',
    '001534': 'Violin Chart',
    '001535': 'Update',
    '001536': 'Token',
    '001537': 'Remaining Time',
    '001538': 'Activation token updated successfully',
    '001539': 'Expiration Date',
    '001540': 'Created Date',
    '001541': 'Key Version',
    '001542': 'Are you sure you want to clear annotations?',
    '001543': 'Unsaved annotations will be removed!',
    '001544': 'Are you sure you want to reset annotations?',
    '001545': 'All saved and unsaved annotations will be removed!',
    '001546': 'Export only completed annotations',
    '001547': 'Are you sure you want to export annotations as json file?',
    '001548': 'Export Annotations',
    '001549': 'Please make a dataset, annotation and model selection in order to view this section.',
    '001550': 'Model Details',
    '001551': 'Go Model Dashboard',
    '001552': 'Show Dataset with Annotations',
    '001553': 'Select Annotator',
    '001554': 'Show Details',
    '001555': 'Approved',
    '001556': 'Rejected',
    '001557': 'Pending',
    '001558': 'In Progress',
    '001559': 'Reviewed',
    '001560': 'Fixed',
    '001561': 'Unreviewed',
    '001562': 'Update Dataset',
    '001563': 'Show Only Selected',
    '001564': 'Select Copied Images',
    '001565': 'Number of Studies / Images',
    '001566': 'Dataset updated successfully',
    '001567': 'Study List',
    '001568': 'Clipboard',
    '001569': 'Reset Clipboard',
    '001570': 'Preview',
    '001571': 'Selected Id copied to clipboard successfully',
    '001572': 'This id is already in the clipboard',
    '001573': 'Selected items removed from clipboard successfully',
    '001574': 'Remove Selected',
    '001575': 'Remove Selected Items',

    '001600': 'Exported Metrics',
    '001601': 'Batch Id',
    '001602': 'Upload Training Model',
    '001603': 'Upload Your Model Here',
    '001604': 'Name',
    '001605': 'Explanation',
    '001606': 'Value Type',
    '001607': 'Selection',
    '001608': 'Default Value',
    '001609': 'Image Channels',
    '001610': 'Back',
    '001611': 'Config is saved successfully',
    '001612': 'Custom Training model uploaded successfully',

    '001700': 'AWS S3',
    '001701': 'Azure Blob',
    '001702': 'Google Drive',
    '001703': 'Add Dataset from Cloud Platform',
    '001704': 'Select Cloud Platform',
    '001705': 'Dataset Type',
    '001706': 'Start Dual Comparison',
    '001707': 'You can view your comparison by selecting',
    '001708': ' Readers ',
    '001709': 'from the top menu.',
    '001710': 'Select Reader',
    '001711':
        'A Bland-Altman plot visualizes the differences in measurements between two different instruments or two different measurement techniques. The x-axis of the plot displays the average measurement of the two instruments and the y-axis displays the difference in measurements between the two instruments.',
    '001712': 'Bland Altman Plot',
    '001713': 'Measurements Selection (Plots)',
    '001714': 'Target Selection',
    '001715': 'Select Table Name',
    '001716': 'Object',
    '001717': 'Measurement',
    '001718': ' Start New Comparison ',
    '001719': 'You can compare readers measurement by clicking',
    '001720': 'button.',
    '001721': 'ICC',
    '001722':
        'In statistics, the intraclass correlation, or the intraclass correlation coefficient, is a descriptive statistic that can be used when quantitative measurements are made on units that are organized into groups. It describes how strongly units in the same group resemble each other. The intra-class correlation coefficient (ICC) is a number, usually found to have a value between 0 and 1.',
    '001723': 'Dice Coefficient',
    '001724':
        'The Dice similarity coefficient, also known as the Sørensen–Dice index or simply Dice coefficient, is a statistical tool which measures the similarity between two sets of data. The value of DSC  lies between 0 to 1.',
    '001726': 'External Model Framework',
    '001727': 'Upload model from external framework',

    '001728': 'Show Metadata',
    '001729': 'Monai Metadata fetched successfully',
    '001730': 'Something went wrong. Please check model name.',
    '001731': 'Model Framework',
    '001732': 'Select Model Framework',
    '001733': 'Upload Monai Model zip file',
    '001734': 'Monai Model Name',
    '001735': 'Enter Model Name',
    '001800': 'Public',
    '001801': 'Private',
    '001802': 'Archived Project',
    '001803': 'Project archived successfully',
    '001804': 'Project unarchived successfully',
    '001805': 'Are you sure you want to archive this project?',
    '001806': 'Are you sure you want to unarchive this project?',
    '001807': 'Archive Project',
    '001808': 'Unarchive Project',
    '001809': 'Dashboard',
    '001810': 'Export only my annotations',
    '001811': 'Local Path',
    '001812': 'Source URL',
    '001813': 'Select Study',
    '001814': 'Edit Model',
    '001815': 'Edit Details',
    '001816': 'Max. Character: 200',
    '001817': 'Description (optional)',
    '001818': 'Calculate Annotation Count',
    '001819': 'Please select ',
    '001820': 'Two Validations ',
    '001821': 'from the top menu.',
    '001822': 'You can then view your comparison by clicking the ',
    '001823': ' button.',
    '001824': 'Uploaded By:',
    '001825': 'Are you sure you want to create curated dataset?',
    '001832': 'Archived Validations',
    '001833': 'Are you sure to unarchive the $ validation ',
    '001834': 'Validation is succesfully unarchived',
    '001835':
        'Explore the project catalogue containing broad variety of computer vision models/datasets or other clinical domains. You can activate project in this page. Visit the page to know more on it.',
    '001836':
        'Visit the page to create a project with any desired model and dataset of your choice. You have the option to add validations to your project, which are available for the chosen models and datasets. Additionally, you can choose whether to make the project private or publicly available.',
    '001837':
        "Take a look at the overview of Models, Datasets and Validation descriptive statistics. Explore historical validation runs and monitor recently scheduled validation job's execution. Visit the page to know more on it. You can not run validation but you have similar options, just like on the summary page.",
    '001838':
        'Schedule a validation / batch run using desired deployed external Model and its compatible dataset. You have the option to either view or export the study result in PDF format.',
    '001839':
        'Dataset analysis refers to the process of examining and assessing the characteristics, properties, and contents of a dataset that is used for training or evaluating artificial intelligence models in the field of healthcare. It involves exploring the dataset to gain insights into its composition, quality, diversity, and potential biases.',
    '001840': 'JSON',
    '001841': 'Upload JSON file',
    '001842': 'Download Dataset',
    '001843': 'Download Annotation',
    '001844': 'Save masks on image',
    '001845': 'Dataset zipping started',
    '001846': 'Dataset zipping completed',
    '001847': 'Click and get download link',
    '001848': 'Selected dataset not found!',
    '001849': 'File zipping completed. Please click on button to get url',
    '001850': 'Zip file path copied to clipboard!',
    '001851': 'Zip file path is not available!',
    '001852': 'Dataset is not available!',
    '001853': 'Loading...',
    '001854': 'Click to get url',
    '001855': 'Annotations',
    '001856': 'Annotation Selection',
    '001857': 'Project Status',
    '001858': 'Dataset Id',
    '001859': 'Schema Version',
    '001860': 'Annotation removed successfully!',
    '001861': 'Are you sure you want to remove this annotation?',
    '001862': 'Add Annotation',
    '001863': 'Invalid values. Please enter numbers between -32768 and +32768 for W and L.',
    '001864': 'Add item',
    '001865': 'The source files for these types of models cannot be viewed.',
    '001866': 'Are you sure you want to remove this batch?',

    //Annotation-Viewer
    '001900': 'Brush',
    '001901': 'Brush Size',
    '001902': 'Brush Opacity',
    '001903': 'Brush Color',
    '001904': 'Erase Segment',
    '001905': 'Show Segments',
    '001906': 'Hide/Show Selected',
    '001907': 'Hide/Show Unselected',
    '001908': '',
    '001909': 'Threshold',
    '001910': 'Cobb Angle',
    '001911': 'Freehand Segmentation',
    '001912': 'Scissor Segmentation',
    '001913': 'Bidirectional Length',
    '001914': '',
    '001915': 'Segment Opacity',
    '001916': 'Fit to Window (F)',
    '001917': 'Smart Circle Annotation',
    '002000': 'Annotation 3D',
    '002001': 'Smart Annotation',
    '002002': 'Reference Line',
    '002003': 'Patient Id',

    '002100': 'Select an annotation type',
    '002101': 'Annotation Type',
    '002102': 'Please select a annotation type',
    '002103': 'Measurement Form',
    '002104': 'Role Selection',
    '002105': 'Label Selection',
    '002106': 'Table Name',
    '002107': 'Recommended Instance!',
    '002108': 'Go to instance!',
    '002109': 'Show Assistance',

    '002200': 'Click',
    '002201': ' "Create Dataset" ',
    '002202': 'to add a new dataset to the platform.',
    '002203': 'Select Create Option',
    '002204': 'Select Extension',
    '002205': 'Dropbox',
    '002206': "The dataset you're deleting is part of curated datasets shown below.",
    '002207': 'Still want to remove it?',

    '002300': 'Go to Model Details',
    '002301': 'Newcomer Guidelines',
    '002304': 'Choose Deployment Host',
    '002302': 'Extracted dataset will be stored in assets folder',
    '002303':
        "Dataset will be stored in your cloud bucket, linked to our platform. Editing or removing  images / dataset won't affect your cloud file system. Changes are only stored in Gesund Platform",
    '002305': 'Agreement generated succesfully.',
    '002306': 'Annotation Agreement',
    '002307': 'Metric Name',
    '002308': 'Base User',
    '002309': 'Select Measurement',
    '002310': 'Object Name',
    '002311': 'Select Object Name',
    '002312': 'Select Base User',
    '002313': 'Target User',
    '002314': 'Select Target User',
    '002315': 'Generate Agreement',
    '002316': 'Please choose options to generate an agreement.',
    '002317': 'Please select more than one user to get comparison scores and metrics',
    '002318': 'To view the comparison scores of readers, please select the readers.',
    '002319': 'Calculate Agreement',
    '002320': 'Total Number of Images:',
    '002321': 'Model Path',
    '002322': 'Model Path added successfully. Please fill the form than click submit button.',
    '002323': 'Please select model options first',
    '002324': 'Go Dataset Dashboard',
    '002325': 'Go Dataset Page',
    '002326': 'Go Model Page',
    '002327': 'Model Mode',
    '002328': 'Access',
    '002329': 'Store Location',
    '002330': 'Created By',
    '002331': 'Please generate agreement first',
    '002332': 'Export Mask',
    '002334': 'SMTP settings updated successfully.',
    '002335': 'Are you sure you want to reset and delete the SMTP settings?',
    '002336': 'SMTP settings deleted successfully.',
    '002337': 'Delete SMTP Settings',
    '002338': 'Update SMTP Settings',
    '002339': 'Add SMTP Settings',
    '002340': 'SMTP Account',
    '002341': 'No Annotation',
    '002342': 'Security Policies',
    '002343': 'Multiple Devices Login',
    '002344': "Are you sure you want to reset user's MFA?",
    '002345': 'Reset MFA',
    '002346': 'This model is an extension of the new version of an existing model.',
    '002347': 'Are you sure you want to incomplete this project?',
    '002348': 'Project status updated',
    '002349': 'Incomplete Project',
    '002350': 'Distribution of Metrics',

    '002400': 'Modality:',
    '002401': 'Tags:',
    '002402': 'Error Logs',
    '002403': 'Close',

    '002250': 'File Explorer',

    '002404':
        'Use the Authenticator app on your device. Scan the QR code (or manually input the shared key), enter the 6 digit code that is displayed below into the form.',
    '002405': 'Authentication Code',
    '002406': 'Disable MFA',
    '002407': 'Please enter your app password in order to disable MFA.',
    '002408': 'Continue with next study',
    '002409': 'Please enter the 6 digit code you receive below, using the Authenticator app on your device.',
    '002410': 'Set all same',
    '002411': 'Re-Generate',
    '002412': 'Invalid Token. Try again.',
    '002413': 'MFA is activated successfully.',
    '002414': 'MFA is deactivated successfully.',
    '002415': 'Assessment',
    '002416': 'Password field can not be empty.',
    '002417': 'Open Project',
    '002418': 'Move the image',
    '002419': 'Change the image',
    '002420': 'Go to next image',
    '002421': 'Go to previous image',
    '002422': 'Jump to next measurement',
    '002423': 'Fit to window',
    '002424': 'Invert',
    '002425': 'Select Brush Tool',
    '002426': 'Show brush tool box',
    '002427': 'Select zoom tool',
    '002428': 'Select W/L tool',
    '002429': 'Reset',
    '002430': 'Assign Annotator',
    '002431': 'Select Annotator',
    '002432': 'Surname',
    '002433': 'Comment',
    '002434': 'Reason',
    '002435': 'Playground 3D',
    '002436': 'Assignment List Table View',
    '002437': 'Custom Presets',
    '002438': 'Multiple Class',
    '002439': 'Selection Component',
    '002440': 'Select a Class',
    '002441': 'Review Required',
} as const;
