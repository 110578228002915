import { IDataset, IDatasetResponsePayload, TransformDataset } from 'common/entities';
import { AnnotationModes, LabelingStrategies, SamplingStrategies } from './INewProjectState';
import {
    ILabelingConfiguration,
    ILabelingConfigurationResponse,
    LabelingConfigurationFactory,
    transformLabelingConfigurationForRequest,
} from './LabelingConfiguration';
import { AnnotationShapeType, AnnotationTool } from './annotation.interface';

export interface IAnnotationTracking {
    datasetId: string;
    annotationId: string;
    annotationName: string;
    createdAt: number;
    updatedAt: number;
    userId: string;
    status?: IAnnotationStatus;
    projectStatus?: IAnnotationProjectStatus;
    description?: string;
    instructions?: string;
    team: Team;
    aiAssistanceConfig?: IAiAssistanceConfig;
    isApproveNeeded?: boolean;
    preAnnotationList?: Array<string>;
    dicomViewer?: boolean;
    imageEvaluation?: IImageEvaluation,
    labelingConfiguration?: Array<ILabelingConfiguration>;
    measurementFormList?: Array<IMeasurementForm>;
    schemaVersion?: string;
    defaultReader?: string;
    colorMap?: Dictionary<string>;
    review_required?: boolean;
    generatedAnnotationAgreement: IAgreementStatus;
    tags?: Array<string>;
    dataset?: IDataset;
    isStudyType?: boolean;
    rateAnnotations?: boolean;
    batch?: Batch;
}

export interface IAnnotationTrackingResponse {
    dataset_id: string;
    created_timestamp: number;
    last_updated_timestamp: number;
    annotation_id: string;
    annotation_name: string;
    user_id: string;
    description?: string;
    instructions?: string;
    assigned_to?: Array<string>;
    status?: IAnnotationStatus;
    project_status?: IAnnotationProjectStatus;
    view_count?: number;
    team: Team;
    ai_assistance_config?: IAiAssistanceConfig;
    is_approve_needed?: boolean;
    pre_annotation_list?: Array<string>;
    dicom_viewer?: boolean;
    image_evaluation?: IImageEvaluation;
    labeling_configuration?: Array<ILabelingConfigurationResponse>;
    measurement_form_list?: Array<IMeasurementForm>;
    schema_version?: string;
    default_reader?: string;
    color_map?: Dictionary<string>;
    review_required?: boolean;
    created_at: number;
    generated_annotation_agreement: IAgreementStatus;
    tags?: Array<string>;
    dataset?: IDatasetResponsePayload;
    isStudyType?: boolean;
    rate_annotations?: boolean;
    batch?: Batch;
}

export function getAnnotationTrackingData(data?: IAnnotationTrackingResponse): IAnnotationTracking {
    return {
        annotationId: data?.annotation_id,
        annotationName: data?.annotation_name,
        datasetId: data?.dataset_id,
        description: data?.description,
        instructions: data?.instructions,
        createdAt: data?.created_timestamp,
        updatedAt: data?.last_updated_timestamp,
        userId: data?.user_id,
        status: data?.status,
        projectStatus: data?.project_status,
        team: data?.team ?? [],
        aiAssistanceConfig: AiAssistanceConfigFactory(data?.ai_assistance_config),
        isApproveNeeded: data?.is_approve_needed,
        preAnnotationList: data?.pre_annotation_list,
        dicomViewer: data?.dicom_viewer,
        imageEvaluation: data?.image_evaluation,
        labelingConfiguration: data?.labeling_configuration?.map(LabelingConfigurationFactory),
        measurementFormList: data?.measurement_form_list,
        schemaVersion: data?.schema_version,
        defaultReader: data?.default_reader,
        colorMap: data?.color_map,
        review_required: data?.review_required,
        generatedAnnotationAgreement: data?.generated_annotation_agreement,
        tags: data?.tags,
        dataset: TransformDataset(data?.dataset),
        isStudyType: data?.isStudyType,
        rateAnnotations: data?.rate_annotations,
        batch: data?.batch ?? [],
    };
}

export function transformDataForRequest(data: Partial<IAnnotationTracking>): IAnnotationTrackingResponse {
    return {
        annotation_id: data.annotationId,
        annotation_name: data.annotationName,
        dataset_id: data.datasetId,
        description: data.description,
        instructions: data.instructions,
        created_timestamp: data.createdAt,
        last_updated_timestamp: data.updatedAt,
        user_id: data.userId,
        status: data.status,
        project_status: data.projectStatus,
        team: data.team,
        ai_assistance_config: data.aiAssistanceConfig,
        is_approve_needed: data.isApproveNeeded,
        pre_annotation_list: data?.preAnnotationList,
        image_evaluation: data?.imageEvaluation,
        labeling_configuration: data?.labelingConfiguration?.map(transformLabelingConfigurationForRequest),
        measurement_form_list: data?.measurementFormList,
        schema_version: data?.schemaVersion,
        default_reader: data?.defaultReader,
        color_map: data?.colorMap,
        review_required: data?.review_required,
        created_at: data?.createdAt,
        generated_annotation_agreement: data?.generatedAnnotationAgreement,
        tags: data?.tags,
        rate_annotations: data?.rateAnnotations,
        batch: data?.batch,
    };
}

export enum AnnotationProjectStatus {
    Active = 'active',
    Project = 'project',
}

export type IAnnotationStatus = 'project' | 'active';
export type IAnnotationProjectStatus = 'inprogress' | 'inreview' | 'completed';

export enum ProjectStatus {
    InProgress = 'inprogress',
    Completed = 'completed',
    InReview = 'inreview',
}

export type Team = Array<ITeamMember>;
export interface ITeamMember {
    id: string;
    role: AssignmentRoleMap;
    assignment: Array<string>;
    review_assignment?: Array<string>;
    statusMap?: Dictionary<CompletionStatus>;
    email?: string;
    displayName?: string;
}
export interface ITeamStudy {
    id: string;
    role: AssignmentRoleMap;
    progress: number;
    assignment: Array<string>;
}

export interface IAnalyticItem {
    userId: string;
    email: string;
    displayName: string;
    organization: string;
    assignmentCount: number;
    completedCount?: number;
    progress?: number;
}

export interface IImageStatistics {
    alias?: string;
    status: string;
    reviewStatus: string;
    createdAt: number;
    updatedAt: number;
    image_id: string;
    study_id: string;
    completion: string;
    missing: number;
}

export enum AssignmentRole {
    Annotator = 'annotator',
    Reviewer = 'reviewer',
    Manager = 'manager',
    Guest = 'guest',
    DataQC = 'data_qc',
}

export type AssignmentRoleMap = Record<AssignmentRole, boolean>;

export type TSamplingStrategy = keyof typeof SamplingStrategies;
export type TLabelingStrategy = keyof typeof LabelingStrategies;
export type TAnnotationMode = keyof typeof AnnotationModes;

export function TeamMemberFactory(data?: Partial<ITeamMember>): ITeamMember {
    return {
        id: data?.id ?? null,
        role: data?.role ?? {
            annotator: false,
            reviewer: false,
            manager: false,
            guest: false,
            data_qc: false,
        },
        assignment: data?.assignment ?? [],
    };
}

export function AiAssistanceConfigFactory(data?: Partial<IAiAssistanceConfig>): IAiAssistanceConfig {
    return {
        enabled: data?.enabled ?? false,
        model_list: data?.model_list ?? [],
    };
}

export interface IAiAssistanceConfig {
    enabled: boolean;
    model_list: Array<IAiAssistanceConfigItem>;
}

export interface IAiAssistanceConfigItem {
    model_id: string;
    configuration: Record<number, number>;
}

export interface IMeasurementForm {
    id?: string;
    name: string;
    object_list: Array<string>;
    measurement_list: Array<IMeasurementSetItem>;
    preferred_series?: string;
}
export interface IMeasurementSetItem {
    id: string;
    name: string;
    label: string;
    shape_type: AnnotationShapeType;
    default_tool?: AnnotationTool;
}

export type CompletionStatus = 'inprogress' | 'completed' | 'approved' | 'partiallyCompleted';
export type ReviewStatus = 'pending' | 'rejected' | 'approved';

export interface IAgreementStatus {
    inter_rating: AnnotationGeneratedStatus;
    intra_rating: AnnotationGeneratedStatus;
    image_inspection: AnnotationGeneratedStatus;
    vendor_metrics: AnnotationGeneratedStatus;
    visual_comparison: AnnotationGeneratedStatus;
}

export enum AnnotationGeneratedStatus {
    DISABLE = 0,
    LOADING = -1,
    GENERATED = 1,
}

export interface IAnnotatorSummary {
    approved: number;
    rejected: number;
    assignmentCount: number;
    completed: number;
    inprogress: number;
    name: string;
    pending: number;
    userId: string;
    fixed?: number;
    unreviewed?: number;
}

export type IStudyPair = {primary_study:string, secondary_study:string};
export interface IImageEvaluation {
    pair_info: IPairInfo;
    table_list: Array<IEvaluationTable>;
}

export interface IEvaluationTable {
    id: string;
    name: string;
    question_groups: Array<IQuestionGroup>;
}

export interface IPairInfo {
    primary_study_title: string;
    secondary_study_title: string;
    distinguishing_metadata: string;
    pair_assignments: Array<IStudyPair>;
}

export interface IQuestionGroup {
    id: string;
    group_name: string;
    questions: Array<IQuestion>;
}

export interface IQuestion {
    id: string;
    question: string;
}
export type Batch = Array<IBatch>;
export interface IBatch {
    name: string;
    study_list?: Array<string>;
    batch_size?: number;
}
