import { updateCommonState } from 'app/store';
import { getReportedErrorLogsTransaction } from 'app/store/actions';
import { notificationReportStep, selectedNotification, selectedNotificationVisible } from 'app/store/selectors';
import cx from 'classnames';
import { APIService, RoutingHelper, _values, getMessages } from 'common';
import { NotificationTypes } from 'common/entities/NotificationTypes';
import { GButton, GModal, GTextArea } from 'components';
import { IGModalProps } from 'components/GModal';
import Notification from 'components/GNotification/index';
import { RedLeftArrow } from 'components/assets/icons';
import dayjs from 'dayjs';
import { useGetAnnotationProjectListCSQuery } from 'pages/AnnotationCS/store/AnnotationService';
import { ValidationStatus } from 'pages/Validation/views/summary/entities/IValidation';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDatasetList, selectModelList, selectValidationList } from 'redux/selectors';
import DownloadJsonReport from './DownloadJsonReport';
import Styles from './Styles.module.scss';
import UploadImage from './UploadImage';

export default function NotificationDetailsModal() {
    const [isLoading, setIsLoading] = useState(false);
    const step = useSelector(notificationReportStep);
    const openModal = useSelector(selectedNotificationVisible);
    const SelectedNotification = useSelector(selectedNotification);
    const validationList = useSelector(selectValidationList);
    const modelList = useSelector(selectModelList);
    const { data: annotationList } = useGetAnnotationProjectListCSQuery({ status: 'project' });
    const datasetList = useSelector(selectDatasetList);
    const filePathRef = useRef<Dictionary<string>>({});
    const dispatch = useDispatch();
    const [additionalInfo, setAdditionalInfo] = useState('');

    if (!SelectedNotification) return null;

    const modalProps: IGModalProps = {
        closable: true,
        open: openModal,
        setVisible: () => dispatch(updateCommonState({ selectedNotificationVisible: !openModal })),
        className: Styles.NotificationDetailsModal,
        footer: null,
        title: getMessages('000821'),
    };

    async function handleClick() {
        try {
            setIsLoading(true);
            const response = await APIService.Notifications.MarkAsReportedNotification.post({
                id: SelectedNotification._id,
                images: _values(filePathRef.current),
                additionalInfo: additionalInfo,
            });
            if (response) {
                Notification.success({ content: getMessages('001047') });
                dispatch(updateCommonState({ selectedNotification: { ...SelectedNotification, reported: true } }));
            }
            dispatch(updateCommonState({ selectedNotificationVisible: false }));
            dispatch(getReportedErrorLogsTransaction());
            filePathRef.current = {};
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const renderButtonText = (title: string) => {
        switch (title) {
            case 'Dataset':
                return 'Go to Dataset';
            case 'Model':
            case 'Model Deployment':
                return 'Go to Model';
            case 'Blind Spot Analysis':
                return 'Go to Blind Spot Analysis';
            case 'Annotation':
                return 'Go to Annotation';
            case 'Validation':
                return 'Go to Validation';
            case 'Annotation 3D':
                return 'Go to 3D Project';
            default:
                return `Go to ${title}`;
        }
    };

    function onNavigateDetails() {
        dispatch(updateCommonState({ selectedNotificationVisible: !openModal }));
        const { entityId, title } = SelectedNotification;
        switch (title) {
            case 'Dataset':
                return RoutingHelper.Dataset({ activeView: 'dataset-details', datasetId: entityId }).navigate();
            case 'Model':
            case 'Model Deployment':
                return RoutingHelper.Model({ activeView: 'model-details', modelId: entityId }).navigate();
            case 'Blind Spot Analysis':
                return RoutingHelper.Validation({
                    activeView: 'analysis',
                    validationId: entityId,
                    subView: 'blindSpotAnalysis',
                }).navigate();
            case 'Annotation':
                return RoutingHelper.Annotation({ activeView: 'annotation-details', projectId: entityId }).navigate();
            case 'Annotation 3D':
                return RoutingHelper.Annotation({
                    activeView: 'annotation-viewer-3D',
                    projectId: entityId,
                    params: SelectedNotification?.additionalInfo ? { mode: 'annotation', 'study':  SelectedNotification?.additionalInfo} : {  mode: 'annotation' },
                }).navigate();
            case 'Validation':
                return RoutingHelper.Validation({ activeView: 'analysis', validationId: entityId }).navigate();
            default:
                break;
        }
    }

    function renderButtonDisabled(): boolean {
        switch (SelectedNotification?.title) {
            case 'Validation':
            case 'Blind Spot Analysis':
                const validation = validationList?.find(validation => validation.validationId === SelectedNotification?.entityId);
                if (validation?.validationStatus === ValidationStatus.done) return false;
                return true;
            case 'Dataset':
                const isDatasetAvaliable = datasetList?.find(dataset => dataset.datasetId === SelectedNotification?.entityId);
                return !isDatasetAvaliable;
            case 'Model':
            case 'Model Deployment':
                const isModelAvaliable = modelList?.find(model => model.modelId === SelectedNotification?.entityId);
                return !isModelAvaliable;
            case 'Annotation':
                const isAnnotationAvaliable = annotationList?.find(
                    annotation => annotation.annotationId === SelectedNotification?.entityId
                );
                return !isAnnotationAvaliable;

            default:
                return false;
        }
    }
    return (
        <GModal {...modalProps}>
            {step === 1 ? (
                <>
                    <div className={Styles.NextStepTitleContainer}>
                        <div className={Styles.errorMessage}>
                            <RedLeftArrow
                                style={{ cursor: 'pointer' }}
                                onClick={() => dispatch(updateCommonState({ notificationReportStep: 0 }))}
                            />
                            <span>REPORT</span>
                        </div>
                        <DownloadJsonReport data={SelectedNotification} />
                    </div>
                    <UploadImage filePathRef={filePathRef} />
                    <GTextArea
                        className={Styles.AdditionalInfo}
                        placeholder="Additional Information"
                        rows={5}
                        onChange={e => setAdditionalInfo(e.target.value)}
                    />
                </>
            ) : (
                <div style={{ textAlign: 'center' }}>
                    {renderNotificationType(SelectedNotification?.type)}

                    <div className={Styles.Modalbody}>
                        <span className={Styles.ModalTitle}>{SelectedNotification?.title}</span>
                        <span className={Styles.ModalMessage}>{SelectedNotification?.message}</span>
                        {SelectedNotification?.reason ? (
                            <span className="pBody-normal, white">Reason: {SelectedNotification?.reason}</span>
                        ) : null}
                        <DownloadJsonReport data={SelectedNotification} />
                        <span className={Styles.ModalDate}>{dayjs.unix(SelectedNotification?.createdAt).format('LLL')}</span>
                    </div>
                </div>
            )}

            <div className={Styles.ButtonBox}>
                {step === 0 && (
                    <GButton
                        type="default"
                        block
                        className={Styles.ModalButton}
                        onClick={() => dispatch(updateCommonState({ selectedNotificationVisible: false }))}
                    >
                        {getMessages('002403')}
                    </GButton>
                )}

                {Boolean(SelectedNotification?.entityId) ? (
                    <GButton
                        disabled={renderButtonDisabled()}
                        onClick={onNavigateDetails}
                        className={Styles.ModalButton}
                    >
                        {renderButtonText(SelectedNotification?.title)}
                    </GButton>
                ) : null}
                {SelectedNotification?.type === NotificationTypes?.error ? (
                    <>
                        <GButton
                            loading={isLoading}
                            type="ghost"
                            disabled={SelectedNotification?.reported}
                            block
                            className={Styles.ModalButtonReport}
                            onClick={() => {
                                dispatch(updateCommonState({ notificationReportStep: step + 1 }));
                                step === 1 && handleClick();
                            }}
                        >
                            {SelectedNotification?.reported ? 'Reported' : step === 0 ? 'Report' : 'Submit'}
                        </GButton>
                    </>
                ) : null}
            </div>
        </GModal>
    );
}

function renderNotificationType(type: string) {
    switch (type) {
        case NotificationTypes.error:
            return (
                <span className={cx(Styles.errorMessage, Styles.marginBottom)}>
                    <span>{getMessages('001049').toLocaleUpperCase()}</span>
                </span>
            );

        case NotificationTypes.info:
            return (
                <span className={cx(Styles.infoMessage, Styles.marginBottom)}>{getMessages('001050').toLocaleUpperCase()}</span>
            );

        case NotificationTypes.success:
            return (
                <span className={cx(Styles.successMessage, Styles.marginBottom)}>
                    {getMessages('001051').toLocaleUpperCase()}
                </span>
            );

        default:
            break;
    }
}
