import HighlightOutlined from '@ant-design/icons/HighlightOutlined';
import { getMessages } from 'common/helpers/MLHelper';
import {
    Angle,
    Brush,
    BrushTool,
    Contrast,
    Download,
    Ellipses,
    EraseSegment,
    FitToWindow,
    Freehand,
    GreenOK,
    GridLine,
    HFlip,
    Invert,
    InvisibleColor,
    Length,
    Pan,
    ProbeTool,
    RectangleControl,
    Redo,
    RefLine,
    ResetViewport,
    RotateL,
    RotateR,
    Scissor,
    SmartCircle,
    StackScrollTool,
    ThresholdTool,
    Undo,
    VerticalFlip,
    VisibleColor,
    ZoomInColor,
} from 'components/assets/icons';
import { DicomViewerControlTool, IControlTool } from './ControlTool';

export function getDicomViewerToolList(list: Readonly<Array<DicomViewerControlTool>>) {
    return list?.map(tool => DicomViewerToolList.find(t => t.key === tool));
}

export const DicomViewerToolList: Array<IControlTool> = [
    {
        key: 'StackScroll',
        title: getMessages('001014'),
        icon: <StackScrollTool />,
        selectable: true,
    },
    {
        key: 'Wwwc',
        title: getMessages('000742'),
        icon: <Contrast />,
        selectable: true,
    },
    {
        key: 'Zoom',
        title: getMessages('000747'),
        icon: <ZoomInColor />,
        selectable: true,
    },
    {
        key: 'Length',
        title: getMessages('000748'),
        icon: <Length />,
        selectable: true,
    },
    {
        key: 'Angle',
        title: getMessages('000749'),
        icon: <Angle />,
        selectable: true,
    },
    {
        key: 'Pan',
        title: getMessages('000753'),
        icon: <Pan />,
        selectable: true,
    },
    {
        key: 'Eraser',
        title: getMessages('001016'),
        icon: <EraseSegment />,
        selectable: true,
    },
    {
        key: 'RectangleRoi',
        title: getMessages('000752'),
        icon: <RectangleControl />,
        selectable: true,
    },
    {
        key: 'EllipticalRoi',
        title: getMessages('000751'),
        icon: <Ellipses />,
        selectable: true,
    },
    {
        key: 'FreehandRoi',
        title: getMessages('000751'),
        icon: <HighlightOutlined />,
        selectable: true,
    },
    {
        key: 'GridLayout',
        title: getMessages('001013'),
        icon: <GridLine />,
    },
    {
        key: 'Invert',
        title: getMessages('000192'),
        icon: <Invert />,
    },
    {
        key: 'ShowBrushToolBox',
        title: getMessages('001419'),
        icon: <BrushTool />,
    },
    {
        key: 'HideAllSegments',
        title: getMessages('001905'),
        icon: <InvisibleColor />,
    },
    {
        key: 'FitToWindow',
        title: getMessages('001916'),
        icon: <FitToWindow />,
    },
    {
        key: 'Reset',
        title: getMessages('000329'),
        icon: <ResetViewport />,
    },
    {
        key: 'HFlip',
        title: getMessages('000187'),
        icon: <HFlip />,
    },
    {
        key: 'VFlip',
        title: getMessages('000188'),
        icon: <VerticalFlip />,
    },
    {
        key: 'RotateR',
        title: getMessages('000300'),
        icon: <RotateR />,
    },
    {
        key: 'RotateL',
        title: getMessages('000301'),
        icon: <RotateL />,
    },
    {
        key: 'Brush',
        title: getMessages('001900'),
        icon: <Brush />,
        selectable: true,
    },
    {
        key: 'BrushEraser',
        title: getMessages('001904'),
        icon: <EraseSegment />,
        selectable: true,
    },
    {
        key: 'FreehandScissors',
        title: getMessages('001911'),
        icon: <Freehand />,
        selectable: true,
    },
    {
        key: 'CorrectionScissors',
        title: getMessages('001912'),
        icon: <Scissor />,
        selectable: true,
    },
    {
        key: 'SphericalGrowCutSegmentation',
        title: getMessages('001917'),
        icon: <SmartCircle />,
        selectable: true,
    },
    {
        key: 'ThresholdsBrush',
        title: getMessages('000831'),
        icon: <ThresholdTool />,
        selectable: true,
    },
    {
        key: 'Undo',
        title: getMessages('000461'),
        icon: <Undo />,
    },
    {
        key: 'Redo',
        title: getMessages('000462'),
        icon: <Redo />,
    },
    {
        key: 'ChangeVisibiliy',
        title: getMessages('001906'),
        icon: <VisibleColor />,
    },
    {
        key: 'Apply',
        title: '',
        icon: <GreenOK />,
    },
    {
        key: 'Download',
        title: getMessages('000740'),
        icon: <Download />,
    },
    {
        key: 'ReferenceLines',
        title: getMessages('002002'),
        icon: <RefLine />,
    },
    {
        key: 'DragProbe',
        title: getMessages('000832'),
        icon: <ProbeTool />,
    },
];

export const PlaygroundControlTools = [
    'StackScroll',
    'Wwwc',
    'Zoom',
    'Length',
    'Angle',
    'Pan',
    'Eraser',
    'RectangleRoi',
    'EllipticalRoi',
    'FreehandRoi',
    'Invert',
    'ShowBrushToolBox',
] as const;

export const AnnotationTools = [
    'Reset',
    'ShowBrushToolBox',
    'HideAllSegments',
    'StackScroll',
    'Wwwc',
    'Zoom',
    'Pan',
    'Eraser',
    'FitToWindow',
    'Invert',
    'HFlip',
    'VFlip',
    'RotateR',
    'RotateL',
    'Download',
    'ReferenceLines',
    'DragProbe',
] as const;

export const BrushToolList = [
    'Brush',
    'BrushEraser',
    'FreehandScissors',
    'CorrectionScissors',
    'ThresholdsBrush',
    // 'SphericalGrowCutSegmentation',
] as const;

export const SmartSegmentationToolList = ['SAMRectPrediction', 'SAMPointPrediction'] as const;
export const SmartSegmentationControlTools = ['Undo', 'Redo', 'Reset', 'Apply'] as const;

export const ActiveSegmentTools = ['Undo', 'Redo'] as const;

export interface BrushToolConfig {
    lineWidth: number;
    opacity: number;
    filled: boolean;
    dashed: boolean;
}

export interface ThresholdToolConfig {
    thresholds: [number, number];
}
